import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";


const breadCrumbLevels = {
    Home: "/",
    "About me": "/en/about",
    "SEO Freelancer": "/en/seo-freelancer"
};

// Hreflang data
const alternateLangs = [
    {
        hreflang: "en",
        href: "/en/seo-freelancer"
    },
    {
        hreflang: "de",
        href: "/de/seo-freelancer"
    },
    {
        hreflang: "x-default",
        href: "/en/seo-freelancer"
    }
];


const SeoFreelancer = props => {

    return (
        <Layout location={props.location} alternateLangs={alternateLangs}>
            <React.Fragment>
                <SEO
                    title="SEO Freelancer for hire: Profile of M. Kupperschmidt"
                    description="I am a freelance SEO consultant with many years of agency experience and you can hire me to bring your website to the top of Googles rankings. Contact me directly."
                    lang="en"
                    image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                    alternateLangs={alternateLangs}
                    canonical="/en/seo-freelancer"
                />
                <MainContent article>
                    <Img
                        src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                        alt="Matthias Kupperschmidt talking about keyword difficulty at SEO consultants meetup in Copenhagen Denmark, October 2019"
                    />
                    <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                    <H as="h1">Hire a freelance SEO consultant</H>
                    <ProfileCard
                        tags={["SEO Content", "Backlinks", "Technical SEO", "Onpage SEO", "HTML/CSS/JavaScript", "React", "Python", "EN, DE, DA"]}
                        profession="SEO Consultant"
                        rate={`from ${monthlySeo}€ monthly`}
                        location="Berlin, Germany / remote"
                        cta="Contact"
                    />
                    <p>I am an SEO freelancer and you can hire me to get your website to the top of Google’s search results.</p>
                    <p>I offer the same service as an SEO agency, just as an independent SEO consultant. I take over the planning, execution and code implementation or work together with your web agency.</p>
                    <p>Previously, I worked in various SEO agencies for enterprise clients and ecommerce businesses doing search engine optimization day in and day out.</p>
                    <p>In 2018, I made the leap to start working as a freelance SEO consultant. Besides optimizing Google’s search engine rankings, I also build professional tracking setups with e.g. Google Analytics and Google Tag Manager.</p>
                    <p>
                        You can read my <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">reviews</a> or learn more about my career on{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            Linkedin.
                        </a>{" "}
                        My personal story and other details are on my <Link to="/en/about">about me page</Link>.
                    </p>
                    <br />
                    <br />
                    <H as="h6" style={{ "textAlign": "center" }}>Who I've worked with</H>
                    <ImgScreenshot
                        src="about/clients_700px.png"
                        alt="clients I worked with"
                        className="article-img"
                    />
                    <br />
                    <br />
                    <br />


                    <H as="h2" style={{ "textAlign": "center" }}>Get a Quote</H>
                    <ContactForm showHeadline={false} formName="ga consultant (EN) - contact form" />

                    <H as="h2">SEO Services</H>
                    <p>
                        Generally, I work on all important SEO ranking factors to optimize websites for search engines, just like
                        any SEO company.
                    </p>
                    <p>Here a short list of my SEO services:</p>
                    <ul>
                        <li>On-page & Off-page optimization</li>
                        <li>Scalable backlink building strategies</li>
                        <li>Content creation in multiple languages</li>
                        <li>JavaScript SEO</li>
                        <li>Load speed optimization</li>
                        <li>Technical SEO consulting</li>
                        <li>Site structure</li>
                        <li>Accelerated Mobile Pages (AMP)</li>
                        <li>no local SEO</li>
                    </ul>
                    <p>Though I focus on SEO, I am well aware that there are other powerful paid traffic sources besides search traffic, e.g. Facebook, Twitter or Google ads. That’s why I implement my SEO techniques with other internet marketing disciplines in mind, so we can <b>leverage organic traffic for retargeting</b> in paid search campaigns and social media campaigns as well.</p>
                    <p>Due to my knowledge about web development, I can also help you with web design tasks in HTML, CSS and JavaScript or configurations in Google Tag Manager and Google Analytics.</p>

                    <H as="h2">Hourly rate and costs</H>
                    <p>My rate for freelance SEO consulting is <b>{hourlyRate} €</b> net per hour. The total costs for an SEO project depend on your own goals, but also on your current site optimization and the efforts of your competitors.</p>
                    <p>Below are some influencing factors that determine the price of my SEO consulting work:</p>
                    <ul>
                        <li>Total number of pages on the domain</li>
                        <li>Age and authority of the domain</li>
                        <li>Competitiveness of niche</li>
                        <li>Technical challenges</li>
                        <li>Adaptability of the frontend</li>
                        <li>Number of languages we optimize for</li>
                        <li>How much new website content will we need?</li>
                        <li>Which link building strategy is required?</li>
                        <li>Are there other partners who cover tasks for content or links?</li>
                    </ul>
                    <br />
                    <p>The hourly minimum is usually <b>at least 15 working hours per month</b>.</p>
                    <p>Potential clients have to trust the judgment of the SEO expert as to how strong the competition is, what site optimization is required, and what opportunities exist.</p>
                    <p>Read on below where I explain <Link to="/en/seo-freelancer#how-to-hire-the-right-freelancer">how to hire the right seo freelancer</Link>.</p>
                    <p>To determine the pricing, the scope of the contract is defined at the project start, based on the above factors.</p>
                    <p>For <b>ongoing SEO optimization</b> a monthly  project scope is based on tasks and milestones. Based on the hourly rate, planned SEO efforts and required working time, SEO costs are plannable and capped for each month.</p>
                    <p>After an agreement, I can begin my work as soon as I get access to the website and related tools.</p>
                    <p>I provide <b>SEO reports</b> in the form of a live dashboard so you have full transparency about keyword rankings and the overall success of my SEO service. I pay for a variety of SEO tools myself and include them in the rate.</p>
                    <p>SEO clients may sometimes only require a <b>small one-off project</b>. For example a <b>load speed optimization</b>, a <b>technical SEO audit, competitor analysis</b> or implementing important landing pages as <b>AMP pages</b>.</p>
                    <p>Such smaller projects are scoped individually and are of course unrelated to the above factors.</p>


                    <H as="h2">Benefits of an SEO freelancer vs. an agency</H>
                    <p>As a general rule, freelancers are a good fit for clients that <b>know what they want</b>.</p>
                    <p>Usually that’s a business that worked with an agency before and has a savvy person inhouse. They have less of a need for hand-holding and rather look for reliable execution. They want the job done with a high degree of certainty without too many powerpoints and upsale tactics coming their way.</p>
                    <p>Freelancers deliver exactly that. They focus on execution while having limited time for communication.</p>
                    <p>In summary, the advantages of working with a freelancer are as follows:</p>
                    <ul>
                        <li><b>better price</b></li>
                        <li><b>flexibility</b> because you can hire on a per need basis</li>
                        <li>more years of <b>experience</b></li>
                        <li>more <b>specialized</b> profiles available</li>
                        <li><b>communication</b> directly with the specialist</li>
                    </ul>

                    <H as="h2">Disadvantages</H>
                    <p>Disadvantages of working with a freelancer are usually related but not limited to scalability. At the end of the day, it is one person you are working with that only has two hands. With a team, you get more work done in a shorter amount of time.</p>
                    <p>Other potential disadvantages are:</p>
                    <ul>
                        <li><b>Scaling limitations:</b> If you expect the number of tasks to increase, one person may not be enough for the job.</li>
                        <li><b>Limitations due to expertise:</b> An agency will have a broader variety of people at their disposal for advice.</li>
                        <li><b>Reliability:</b> Illness or unexpected life disruptions can be better compensated with a team at hand.</li>
                        <li><b>Personality mismatch:</b> If you don’t click with your freelancer you may have to move on and wasted time. An agency would be able to put you in contact with another person.</li>
                    </ul>


                    <H as="h2">How to hire the right freelancer?</H>
                    <p>When hiring SEO freelancers, it is important to validate the experience and SEO skills of each profile.</p>
                    <p>For example, you will want to make sure that your website’s technology matches the freelancer’s past projects. If you run a WordPress website, most freelancers will be comfortable working with it. But if you run a very customized web stack with e.g. React or Angular in the frontend and Sitecore or Umbraco in the backend, you will want to ensure that it doesn’t pose any challenges.</p>
                    <p>Individual SEO specialists may not be used to implementing changes via Git, but your website requires that. So talk through the process of implementing changes on the website to uncover potential bottlenecks.</p>
                    <p>Some companies build their web presence themselves with an in-house developer, while others work with a web agency. This has implications for SEO consulting since changes can either be implemented directly or have to go through an external agency. That fact alone impacts cost and the required time for an SEO campaign because more layers of communication are necessary until a change is implemented.</p>
                    <p>So independent SEO experts have to be comfortable with the given setup of internal and external teams and consider its impact on the overall SEO strategy.</p>
                    <p>Also the track record and where the freelancer worked before play a role to draw conclusions about work ethics and reliability. The entry barrier for seo freelancers from Upwork is pretty low and may therefore not deliver right candidate (not saying its not possible though). Therefore I hope to offer some transparency by sharing with you my{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            Linkedin profile
                        </a>{" "}
                        upfront.
                    </p>
                    <p>Once you find somebody, a simple email or filled-out contact form should be enough to initiate a talk. A professional SEO freelancer though doesn’t take every project that’s falling onto the table. And that is good for clients, so don’t take a rejection personal.</p>
                    <p>There are varying SEO strategies for different niches and keyword intents. Freelancers will want to make sure the niche’s requirements and website technology matches their expertise.</p>
                    <p>If they don’t, customers should be skeptical. Unless you need a somewhat standard SEO task delivered, you want to find an SEO freelancer, that is an expert for your case.</p>
                    <p>After the project scope and costs are agreed upon in writing, the freelancer needs access to the website. That means access to the frontend, CMS and analytics and potentially an FTP server. As soon as access is granted, the SEO expert is hired and the working time officially starts.</p>
                    {/* 
          <H as="h2">My Profile (hard skills)</H>
          <p>I spent the last {experienceYears} years helping businesses grow revenue with digital marketing and analytics. I don't exclusively do this for clients. I run and operate multiple websites for hobbies and affiliate marketing myself.</p>

          <p>I work with some of the biggest brands globally as well as nationally in Denmark and Germany doing freelance SEO consulting.</p>

          <p>The majority of the SEO projects are for multilingual websites where I do international SEO and my language skills (German, English, Spanish, Danish) and technical skills fit well into my client's needs.</p>

          <p>I don't do local SEO for targeting the results in the local pack.</p>

          <p>I work a lot in the food & health sector, but I also did industries like insurance, technology, automotive and travel, to name a few.</p>

          <p>Though I can <b>code in a few programming languages</b>, I don't like to limit myself to technical SEO. I do Off-Page SEO and even copywriting for optimizing content as well, which I consider more important to increase search engine rankings. </p>

          <p>Typically, however, I work together with inhouse writers and developers because there is only so much my two hands could get done in one day.</p>

          <p>My technical competencies help me to get things implemented fast myself or to communicate properly with developers.</p>

          <p>If you want cheap backlinks fast, I won't do it. I will not take on a project or execute a plan I am not convinced of myself (sorry). It would otherwise hurt my reputation.</p>

          <p>In terms of link building, I base my approach on the niche and what works successfully for competitors, paired with budget and risk considerations.</p>

          <p>Another important discipline in my past was creating relevant user-segments to use for retargeting campaigns with Facebook, Adwords (now Google Ads) or Linkedin. Meaning,  I can help you with Google Tag Manager implementations and Google Analytics configurations to use organic traffic for paid traffic campaigns too.</p>

          <H as="h3">Personal skills</H>

          <p>My soft skills allow me to be comparably social in a techy environment. I learned many times that I have it easier than other developers for example to speak openly and bring my point across without many detours and a slight smile</p>

          <p>I speak my mind directly. In fact, I consider it a professional skill to state disagreement, so my client has all information available to take a decision. But I'm aware of cultural differences. It's a touchy subject.</p>

          <p>My personality is analytical and detail-oriented. I execute tasks thoroughly with precision instead of going broad too fast. Getting lost in perfectionism is often the downside for analysts, but I am actually very pragmatic and result-oriented.</p>

          <p>The other strong side of my character is dominance. I have discipline and drive projects forward.</p>

          <p>I'm not making this up. These personality insights are based on a number of personality tests I did in the past. Such Tests focus on personality as well as soft- and hard-skills, namely by <b>Thomas International</b> (PPA) and <b>Insights Discovery</b> (DISC).</p>

          <p>About my <b>personal style</b> they say for example: </p>

          <p><i>"Matthias has a gift for seeing the important facts of a situation. His need to detach himself from his emotions allows him to retain objectivity when assisting others in times of stress. His interest lies in seeing possibilities beyond what is already present and known, by using his insight,
ingenuity and intellect. He is highly independent and can live and work quite contentedly in modest surroundings."</i></p>

          <p>But let's talk about what's interesting: What are my <b>weaknesses</b>? After all, if you're considering to hire me, you should get an idea what I'm not good at. So here it goes:</p>

          <p><i>"Matthias would sometimes benefit from finding out what matters emotionally to others. He has a “let's do it and not talk about it” approach to work, which others may find difficult to handle.
He may be seen by some as unresponsive, cool and uncaring, as one who constantly seeks correctness, predictability, analysis, logic, routine and systems. He can be reserved and hard to get to know, only willing to share his inner feelings with people he trusts."</i></p>

          <p>Yep. I hope that draws a picture.</p>

          <p>My freelance consulting work is based in Copenhagen, Denmark and I also sometimes work from Northern Germany, Berlin or Hamburg, where my family lives. </p>

          <p>During winter, I try to work from my wife's home in Indonesia. I'm a fan of isolation. It's perfect to concentrate on my tasks. For the most part of the year though, I am usually in Europe.</p>
 */}
                    <H as="h2">How did I become a freelancer for search engine optimization?</H>
                    <p>I built my first private homepage at the age of 14 so that I could share my film collection with school friends. In our village, I was also the kid who repaired the computers and went through many operating system re-installs.</p>
                    <p>
                        When I was 19, I built Kickerkult.de, an e-commerce shop for table soccer and started doing search engine
                        optimization for my own keyword research. Back then though, optimizing meta keywords and title tags almost
                        was enough.
                    </p>
                    <p>Tinkering with websites and technology as a whole was always fun for me, so I must have naturally chosen to work full time in web agencies and online marketing later on. The colleagues and projects in those agencies were a good influence to expand my skillset and make my workflows and processes more professional.</p>
                    <p>After I had my fair share of SEO jobs, progress together with a digital marketing agency became increasingly difficult, so that freelance SEO consulting was the logical next career step for me.</p>
                    <p>Most <a href="https://www.statista.com/statistics/946967/freelancers-in-europe-by-sector/" target="_blank">freelancers in Europe (39%) work in the marketing and communication sector</a>. I wanted to go deeper into my subject and control what projects I work on, so freelancing became the next move to ensure that each hour is spent on a meaninful task.</p>
                    <p>It turns out I'm not totally alone with that thinking. In fact, <a href="https://apo.org.au/sites/default/files/resource-files/2016-05/apo-nid201721.pdf" target="_blank">freelancers or iPros are the fastest growing group in the EU labour market since 2004</a> and <a href="https://news.malt.com/wp-content/uploads/2019/02/FREELANCING-IN-EUROPE-2-1.pdf" target="_blank">they account for about 7% of the total workforce in the EU</a>.</p>
                    <p>I can spend more time now on the content, keyword research and link building, because I need less time for administrative tasks or project management, while my clients get more actual work for their budget. Freelancing full time therefore provides me with a faster feedback-loop, which is necessary to compete and usually not achievable in an SEO agency.</p>
                    <p>I have been working as a freelance SEO specialist since 2018 now. I work mostly with enterprise clients that have multinational websites, as well as e-commerce shops following an approach driven by content marketing. I don’t do local SEO.</p>

                    <H as="h3">Looking for SEO help?</H>
                    <p>
                        If you are looking for a freelance SEO expert to help you with your Google rankings, feel free to contact
                        me. I'll usually get back with an assessment of the situation and a plan including a quote.
                    </p>
                    <p>
                        You can otherwise find more information on my <Link to="/en/about">about page</Link> and potentially some
                        interesting reads on my <Link to="/en/blog">blog</Link>.
                    </p>
                    <H as="h4" style={{ "textAlign": "center" }}>Contact me for a non-binding offer.</H>
                    {/* taking the schema off due to bad implications potentially */}
                    {/* <Helmet>
                    <script type="application/ld+json">{Schema}</script>
                </Helmet> */}
                </MainContent>
            </React.Fragment>

        </Layout>
    );
};

export default SeoFreelancer;
